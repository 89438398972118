import React from "react";
import Position from "./Position";
import Branch from "./Branch";
import {CardHeader, CardBody, CardTitle} from "reactstrap";
import {Tabs, Card, Row, Col, Tab} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
import Project from "./Project";

function Settings() {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Card className=" ">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Setting </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="all-icons mr-4 ml-4">
                                <div id="icons-wrapper">
                                    <Tabs defaultActiveKey="Position" id=" " className="">
                                        <Tab eventKey="Project" title="Project">
                                            <Project headers={headers}/>
                                        </Tab>
                                        <Tab eventKey="District" title="District">
                                            <Branch  headers={headers}/>
                                        </Tab>

                                        <Tab eventKey="Position" title="Position">
                                            <Position headers={headers}/>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Settings;
