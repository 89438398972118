import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Form, FormGroup, Modal, Row, Table, Spinner, Dropdown} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

export default function Branch(headers) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showAddClassItem, setShowAddClassItem] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [province, setProvince] = React.useState([]);
    const [project, setProject] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [property, setProperty] = React.useState({});

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchProvince();
        fetchProject();
        fetchList();
    }, []);
    const fetchProvince = async () => {
        const {data} = await Axios.get(mainUrl + "getProvince", headers);
        const lists = data;
        setProvince(lists);
    };
    const fetchProject = async () => {
        const {data} = await Axios.get(mainUrl + "getProject", headers);
        const lists = data;
        setProject(lists);
    };
    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getBranch", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addBranch = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addBranch", {
            id: e.target.id.value,
            province_id: e.target.province_id.value,
            project_id: e.target.project_id.value,
            name: e.target.name.value
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>

            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (
                    <div>
                        <AddItem/>
                        <TableData/>
                    </div>
                )}
            </div>
        </>
    );

    function DeleteItem() {

        const onDeleteRecord = async (e) => {
            e.preventDefault();
            await Axios.post(mainUrl + "deleteBranch", {
                id: recordId
            }, headers).then(res => {
                notifySuccess("Successfully deleted");
                const lists = res.data;
                setList(lists);
            }).catch((error) => {
                notifyError(error.response.data.message);

            });
            setRecordId(0);
            setShowDeleteModal(false);
        }

        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }


    function AddClassItem() {

        const [classList, setClassList] = React.useState([]);
        const [showAddItems, setShowAddItems] = React.useState(false);
        const [showClassItmeDeleteModal, setShowClassItmeDeleteModal] = React.useState(false);
        const [recordClassId, setRecordClassId] = React.useState(0);
        useEffect(() => {
            fetchClassList();
        }, []);
        const fetchClassList = async () => {
            const {data} = await Axios.post(mainUrl + "getClass", {
                branch_id: property.branch_id,
            }, headers);
            const lists = data;
            setClassList(lists);
        };
        const onAddClassItemRecord = async (e) => {
            e.preventDefault();
        }


        function DeleteItemClass() {

            const onDeleteRecord = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "deleteClass", {
                    id: recordClassId,
                    branch_id: property.branch_id,
                }, headers).then(res => {
                    notifySuccess("Successfully deleted");
                    const lists = res.data;
                    setClassList(lists);
                }).catch((error) => {
                    notifyError(error.response.data.message);

                });
                setRecordClassId(0);
                setShowClassItmeDeleteModal(false);
            }

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showClassItmeDeleteModal} backdrop="static" size="md"
                           onHide={() => setShowClassItmeDeleteModal(false)}>
                        <form onSubmit={(e) => onDeleteRecord(e)}>
                            <Modal.Header>
                                <div className="modal-profile">
                                    <h2>Confirmation</h2>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Col md="12">
                                        Are you sure you want to delete?
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowClassItmeDeleteModal(false)}>
                                    Close
                                </Button>
                                <Button title="Yes , I am sure" className="btn-round" color="danger" type="submit">
                                    Yes
                                </Button>
                            </div>
                        </form>
                    </Modal>
                </>
            );
        }

        function AddItems() {

            const addClassItems = async (e) => {
                e.preventDefault();
                await Axios.post(mainUrl + "addClass", {
                    id: e.target.class_id.value,
                    branch_id: property.branch_id,
                    name: e.target.class_name.value,
                    code: e.target.class_code.value,
                    y1: e.target.y1.value,
                    y2: e.target.y2.value,
                    y3: e.target.y3.value,
                    y4: e.target.y4.value,
                    x1: e.target.x1.value,
                    x2: e.target.x2.value,
                    x3: e.target.x3.value,
                    x4: e.target.x4.value,
                    total_student: e.target.total_student.value
                }, headers).then(res => {
                    if (e.target.id.value == 0) {
                        notifySuccess(' Added Successfully.');
                    } else {
                        notifySuccess(' Updated Successfully.');
                    }
                    setClassList(res.data);
                    setShowAddItems(false);
                }).catch(function (error) {
                    notifyError(error.response.data.message);
                    setShowAddItems(false);
                });
            };

            return (
                <>
                    <Modal style={{paddingTop: "50px"}} show={showAddItems} backdrop="static" size="md"
                           onHide={() => setShowAddItems(false)}>
                        <Form onSubmit={(e) => addClassItems(e)}>
                            <Modal.Header className="justify-content-center">
                                <div className="modal-profile">
                                    New Class
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="pr-3">
                                    <Input hidden={true} required name="class_id" id="class_id" value="0"
                                           type="hidden"/>
                                    <Col className="" md="12">
                                        <FormGroup>
                                            <label>Class Code <span className="text text-danger">*</span></label>
                                            <Input required name="class_code" id="class_code"
                                                   placeholder="Class code" title="Class code" type="text"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Class name <span className="text text-danger">*</span></label>
                                            <Input required name="class_name" id="class_name"
                                                   placeholder="Class name" title="Class name" type="text"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Number of Register students<span className="text text-danger">*</span></label>
                                            <Input required name="total_student" id="total_student"
                                                   placeholder="Number of Register students" title="Number of Register students" type="number"/>
                                        </FormGroup>
                                        <div style={{border: '1px solid gray',margin:'20px',padding:'20px'}}>
                                            <table >
                                                <tr>
                                                    <td>x1,y1</td>
                                                    <td></td>
                                                    <td>x2,y2</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <div style={{backgroundColor:'gray',border: "2px solid black",width:'150px', height:'80px',textAlign:'center',paddingTop:'20%'}}>
                                                            Class Area
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>x3,y3</td>
                                                    <td></td>
                                                    <td>x4,y4</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div>
                                            <div style={{whiteSpace:'nowrap'}}> x1: <input name="x1" id="x1" type="text" style={{width:"120px"}}></input> , y1: <input name="y1" id="y1" type="text" style={{width:"120px"}}></input></div>
                                            <div style={{whiteSpace:'nowrap'}}> x2: <input name="x2" id="x2" type="text" style={{width:"120px"}}></input> , y2: <input name="y2" id="y2" type="text" style={{width:"120px"}}></input></div>
                                            <div style={{whiteSpace:'nowrap'}}> x3: <input name="x3" id="x3" type="text" style={{width:"120px"}}></input> , y3: <input name="y3" id="y3" type="text" style={{width:"120px"}}></input></div>
                                            <div style={{whiteSpace:'nowrap'}}> x4: <input name="x4" id="x4" type="text" style={{width:"120px"}}></input> , y4: <input name="y4" id="y4" type="text" style={{width:"120px"}}></input></div>

                                        </div>
                                    </Col>
                                </Row>

                            </Modal.Body>
                            <div className="modal-footer">
                                <Button title="Close"
                                        className="btn-simple"
                                        type="button"
                                        variant="link"
                                        onClick={() => setShowAddItems(false)}>
                                    Close
                                </Button>
                                <Button title="Insert & update  Record" className="btn-round" color="danger"
                                        type="submit">
                                    Add
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                </>
            );
        }

        let no = 1;
        return (
            <>
                <AddItems/>
                <DeleteItemClass/>
                <Modal show={showAddClassItem} backdrop="static" size="lg" onHide={() => setShowAddClassItem(false)}>
                    <Modal.Header>
                        <div className="row" style={{width: "100vw"}}>
                            <div className="ml-right col-md-12">
                                <h4> Add Class Item List </h4>
                            </div>

                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>Project name</td>
                                    <td><span className="text-info">{property.project_name}</span></td>
                                </tr>
                                <tr>
                                    <td>Province name</td>
                                    <td><span className="text-info">{property.province_name}</span></td>
                                </tr>
                                <tr>
                                    <td>District name</td>
                                    {/*<td>Branch name</td>*/}
                                    <td><span className="text-info">{property.branch_name}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className="card-user">

                                    <CardBody>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setShowAddItems(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                        <div style={{height: '350px', overflowY: 'scroll'}}>
                                            <Table>
                                                <thead className="text-primary">
                                                <tr>
                                                    <th>No</th>
                                                    <th style={{width: '33%'}}>Class Code</th>
                                                    <th style={{width: '33%'}}>Class Name</th>
                                                    <th style={{width: '33%'}}>Number of Register students</th>
                                                    <th className="text-right">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {classList.map((e) => (
                                                    <tr key={e.id}>
                                                        <td>{no++}</td>
                                                        <td style={{width: "33%"}}>{e.code}</td>
                                                        <td style={{width: "33%"}}>{e.name}</td>
                                                        <td style={{width: "33%"}}>{e.total_student}</td>

                                                        <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                                    <span className="nc-icon nc-bullet-list-67"/>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Button title="Update & Detail View Record"
                                                                            onClick={async () => {
                                                                                await setShowAddItems(true);
                                                                                document.getElementById('class_code').value = e.code;
                                                                                document.getElementById('class_name').value = e.name;
                                                                                document.getElementById('total_student').value = e.total_student;
                                                                                document.getElementById('class_id').value = e.id;
                                                                                document.getElementById('x1').value = e.x1;
                                                                                document.getElementById('x2').value = e.x2;
                                                                                document.getElementById('x3').value = e.x3;
                                                                                document.getElementById('x4').value = e.x4;
                                                                                document.getElementById('y1').value = e.y1;
                                                                                document.getElementById('y2').value = e.y2;
                                                                                document.getElementById('y3').value = e.y3;
                                                                                document.getElementById('y4').value = e.y4;
                                                                            }}  className="btn-round dropdownlist" variant="light" type="Button" >
                                                                        <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                        Update & Detail View Record
                                                                    </Button>
                                                                    <br/>
                                                                    <Button title="Delete Record" onClick={() => {
                                                                        setRecordClassId(e.id);
                                                                        setShowClassItmeDeleteModal(true);
                                                                    }}  className="btn-round dropdownlist" variant="light" type="Button"
                                                                    >
                                                                        <span className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                                        Delete Record
                                                                    </Button>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => setShowAddClassItem(false)}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => addBranch(e)}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile">
                                {/*New Branch*/}
                                New District
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="id" id="id" value="0" type="hidden"/>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Project</label>
                                        <select id="project_id" required
                                                className="form-control">
                                            <option value=""></option>
                                            {project.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label>Province</label>
                                        <select id="province_id" required
                                                className="form-control">
                                            <option value=""></option>
                                            {province.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>District name <span className="text text-danger">*</span></label>
                                        {/*<label>Branch name <span className="text text-danger">*</span></label>*/}
                                        <Input required name="name" id="name"
                                               placeholder="District name" title="Branch name" type="text"/>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <DeleteItem/>
                <AddClassItem/>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> District List </h2>
                                            {/*<h2> Branch List </h2>*/}
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-success" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Project</th>
                                        <th>Province</th>
                                        <th>District Name</th>
                                        {/*<th>Branch Name</th>*/}
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "33%"}}>{e.project_name}</td>
                                            <td style={{width: "33%"}}>{e.province_name}</td>
                                            <td style={{width: "33%"}}>{e.branch_name}</td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Update & Detail View Record"
                                                                onClick={async () => {
                                                                    await setShowModal(true);
                                                                    document.getElementById('name').value = e.branch_name;
                                                                    document.getElementById('project_id').value = e.project_id;
                                                                    document.getElementById('province_id').value = e.province_id;
                                                                    document.getElementById('id').value = e.id;
                                                                }} className="btn-round dropdownlist" variant="light"
                                                                type="Button">
                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record"
                                                                onClick={() => {
                                                                    setProperty({
                                                                        "branch_id": e.id,
                                                                        "project_name": e.project_name,
                                                                        "province_name": e.province_name,
                                                                        "branch_name": e.branch_name,
                                                                    });
                                                                    setRecordId(e.id);
                                                                    setShowAddClassItem(true);
                                                                }}
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                        >
                                                            <span className="nc-icon nc-album-2 text-info"/>&nbsp;&nbsp;
                                                            Add Class
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                        >
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
