import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl, toBase64} from "../../common/common";
import {Button, Col, Modal, Row, Spinner, Table, Dropdown} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";
import {height, maxHeight, width} from "@material-ui/system";
import {ZIndex as styles} from "@material-ui/core/styles/zIndex";
import Input from "@material-ui/core/Input";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListMessage(props) {

    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const [showMessageModal, setShowMessageModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const [chatList, setChatList] = React.useState([]);
    const loadMessageList = async (userId) => {
        await Axios.post(mainUrl + "messageList", {'user_id': userId}, {headers}).then(res => {
            const lists = res.data;
            setChatList(lists.reverse());
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        await setShowMessageModal(true);
        document.getElementById('chat_list').scrollTop = document.getElementById('chat_list').scrollHeight;
    }

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchMessage?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getMessage?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }
    }

    function MessageList() {
useEffect(() => {},[setChatList])
        const addMessage = async (e) => {
            e.preventDefault();
            const f = e.target;
            await Axios.post(mainUrl + "addMessages", {
                employee_id: recordId,
                message: f.message.value
            }, {headers}).then(res => {
                document.getElementById('append').innerHTML +=("<div>"+f.message.value +"<hr/></div>");
                document.getElementById('message').value='';
            }).catch(function (error) {
                props.notifyError(error.response);
            });
        }

        return (
            <>
                <Modal show={showMessageModal} backdrop="static" size="lg" onHide={() => setShowMessageModal(false)}
                       sytle={{marginTop: '300px!important     '}}>
                    <Modal.Header>
                        <div className="modal-profile" style={{display: 'flex'}}>
                            <h2>Chat List</h2>
                            <span style={{
                                backgroundColor: 'red',
                                borderRadius: '45%',
                                right: '10px',
                                position: 'absolute',
                                padding: '5px',
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                                  onClick={() => setShowMessageModal(false)}
                                  role="button">
                                &nbsp;&nbsp;X&nbsp;&nbsp;
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body id='chat_list' style={{height: '500PX', overflowY: 'auto'}}>
                        <Row className="pr-3" style={{height: '500px!important'}}>
                            <Col md="12"  id='append' >
                                {chatList.map((e, index) => (
                                    <div key={index} style={{textAlign: e.response_detail!=null && 'right'}}>
                                        {e.type === 1 && e.message}
                                        {e.type === 2 && <img width="300" height="auto" src={e.message} style={{
                                            backgroundColor: "#8080805e!important"
                                        }}/>}
                                        <br/>
                                        <span className="badge badge-default">{e.created_at}</span><br/>
                                        <span className="badge badge-warning">{e.response_detail}</span>
                                        <hr/>
                                    </div>
                                ))
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <div className="modal-footer">
                        <form onSubmit={(e) => addMessage(e)}>

                            <Row style={{width: '600px',display: 'inline-flex'}}>
                                <Col style={{display: 'flex'}}>
                                <textarea name="message" id="message" style={{width: '90%'}} placeholder="Type a message..." type="text">
                                </textarea>
                                    &nbsp;&nbsp;
                                    <button role="Button" type="submit" className='' style={{color: 'primary',}}>
                                        <span className="nc-icon nc-send"></span></button>
                                </Col>
                            </Row>
                        </form>

                    </div>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <MessageList/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> User Message </h2>
                                    </div>
                                    <div className="ml-end">

                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>Employee Id</th>
                                        <th>Employee Name</th>
                                        <th>Project</th>
                                        <th>Province</th>
                                        <th>District</th>
                                        <th>Message</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            <td>{rowNo++}</td>
                                            <td style={{width: "10%"}}> ( {e.employee_id} )</td>
                                            <td style={{width: "10%"}}> {e.employee_name}</td>
                                            <td style={{width: "10%"}}>{e.project_name}</td>
                                            <td style={{width: "10%"}}>{e.province_name}</td>
                                            <td style={{width: "10%"}}>{e.branch_name}</td>
                                            <td style={{width: "50%"}}>
                                                <Button title="Show Message"
                                                        className="btn-round " variant="light"
                                                        type="Button"
                                                        onClick={() => {
                                                            setRecordId(e.emp_id);
                                                            loadMessageList(e.id);
                                                        }}>

                                                    <span className="nc-icon nc-chat-33 text-info"/> &nbsp;&nbsp;
                                                    Show Messages
                                                </Button>

                                            </td>

                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                            : "")}
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListMessage;
