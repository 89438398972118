import React, {useEffect, useState} from "react";
import ImageSelect from "./utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Card, Input} from "reactstrap";
import Axios from "axios";
import {mainUrl, toBase64, dataURLtoFile} from "../../common/common.js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

function AddProfile(props) {

    const [showUploadImage, setShowUploadImage] = useState(false);
    const [provinceSubList, setProvinceSubList] = useState([]);

    const [isPorcessGetClass, setIsPorcessGetClass] = useState(false);
    const [projectId, setProjectId] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [downloadStatus, setDownloadStatus] = useState(false);
    const [branchSubList, setBranchSubList] = useState([]);
    const [classSubList, setClassSubList] = useState([]);

    const [classLoadStatus, setClassLoadStatus] = useState(true);

    const [no, setNo] = useState(0);
    useEffect(() => {
        setDisabled(true);
        setTimeout(() => props.setLocalImageProfile(sessionStorage.getItem("image")), 1000);
    }, []);

    useEffect(() => {
        setTimeout(async () => {
            if (props.updateRecordStatus == true) {
                if (props.subAdminStatus == 1 || props.subAdminStatus == 2) {
                    await onProjectChange(props.needObject.project_id)
                        .then(r => {
                            if (document.getElementById('branch_id')) {
                                document.getElementById('province_id').value = props.needObject.province_id;
                                onProvinceChange(props.needObject.province_id)
                                    .then(r1 => {
                                        if (document.getElementById('branch_id')) {
                                            document.getElementById('branch_id').value = props.needObject.branch_id;
                                            if (props.subAdminStatus == 1) {
                                                onBranchChange(props.needObject.branch_id)
                                                    .then(r2 => {
                                                        if (document.getElementById('class_id')) {
                                                            document.getElementById('class_id').value = props.needObject.class_id;
                                                            props.setUpdateRecordStatus(false);
                                                        }
                                                    });
                                            } else {
                                                props.setUpdateRecordStatus(false);
                                            }
                                        }
                                    });
                            }
                        });
                }
            }
        }, 500);
    }, [props.updateRecordStatus, provinceSubList, branchSubList, classSubList]);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        console.log(e);
        let attachFile = "";
        if (f.attachment.files[0] != null && f.attachment.files[0] != "") {
            attachFile = await toBase64(f.attachment.files[0]);
        }
        await Axios.post(mainUrl + "addProfile", {
            id: f.ids.value,
            employee_id: f.employee_ids.value,
            name: f.name.value,
            father_name: f.father_name.value,
            email: f.email.value,
            phone: f.phone.value,
            gender_id: f.gender_id.value,
            province_id: f.province_id.value,
            branch_id: f.branch_id.value,
            position_id: f.position_id.value,
            class_id: f.class_id.value,
            project_id: f.project_id.value,
            salary: f.salary.value,
            attachment: attachFile,
            remark: f.remark.value,
            status_id: f.status_id.value,
            image: f.image.value,
            defaultPassword: f.defaultPassword.value,
            user_type_id: f.user_type_id.value,

        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setList(res.data);
            props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
    };

    const onProjectChange = async (e, index) => {
        // e.preventDefault();
        setProjectId(e);
        let newArray = props.branchList.filter(function (el) {
            return el.project_id == e;
        }).map(a => a.province_id);
        let res = props.provinceList.filter(function (el) {
            return newArray.includes(el.id);
        })
        setProvinceSubList(res);
        setBranchSubList([]);
        setClassSubList([]);
    };

    const onProvinceChange = async (e) => {
        // e.preventDefault();
        let newArray = props.branchList.filter(function (el) {
            return el.project_id == projectId && el.province_id == e;
        });
        setBranchSubList(newArray);
        setClassSubList([]);
    };

    const onBranchChange = async (e) => {
        new Promise(r => setTimeout(r, 10000));

        if (!isPorcessGetClass) {
            setIsPorcessGetClass(true);
            setClassLoadStatus(false);
            // e.preventDefault();
            await Axios.post(mainUrl + "getClass", {
                branch_id: e
            }, {headers}).then(res => {
                setClassSubList(res.data);
                setClassLoadStatus(true);
                props.setUpdateRecordStatus(false);
                setIsPorcessGetClass(false);
            }).catch(function (error) {
                props.notifyError(error.response.data.message);
                props.setUpdateRecordStatus(false);
                setIsPorcessGetClass(false);
            });
        }
    };

    const onUserTypeChange = async (e) => {
        e.preventDefault();
        document.getElementById('project_id').value = "";
        document.getElementById('province_id').value = "";
        document.getElementById('branch_id').value = "";
        document.getElementById('class_id').value = "";
        if (e.target.value == 1) {
            props.setSubAdminStatus(1);
        } else if (e.target.value == 2) {
            props.setSubAdminStatus(2);
        } else if (e.target.value == 3) {
            props.setSubAdminStatus(3);
        }
    };

    const downloadFile = async () => {
        setDownloadStatus(true);
        const f = document.getElementById('ids').value;
        await Axios.post(mainUrl + "getAttachFile", {
            employee_id: f
        }, {headers}).then(res => {
            var filePDf = dataURLtoFile('data:application/x-zip-compressed;base64,' + res.data, 'attachment.zip');
            const previewUrl = window.URL.createObjectURL(filePDf);
            const anchor = document.createElement("a");
            anchor.download = "attachment.zip";
            anchor.href = URL.createObjectURL(filePDf);

            anchor.click();
            window.URL.revokeObjectURL(previewUrl);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
        });
        setDownloadStatus(false);
    }
    return (
        <>
            {showUploadImage == true && <ImageSelect setLocalImageProfile={props.setLocalImageProfile}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onSubmit={(e) => addProfile(e)}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            New Profile
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <div className=" btn"
                                 style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                 title="Image">
                                <img src={props.localImageProfile != "" ? props.localImageProfile : image}
                                     style={{borderRadius: "50%", backgroundColor: "#8080805e!important"}}
                                     onClick={() => {
                                         if (!showUploadImage) {
                                             setShowUploadImage(true);
                                         } else {
                                             setShowUploadImage(false);
                                         }
                                     }} width="125" height="125"/>
                                <div style={{
                                    position: "absolute",
                                    top: "89%",
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                    whiteSpace: "nowrap"
                                }} className="text-primary">Select Image
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="image" id="image" value={props.localImageProfile}/>
                        <div style={{height: '500px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                            <Row className="pr-3">
                                <Col md="6">
                                    <FormGroup>
                                        <label>Employee ID</label>
                                        <Input type="hidden" name="ids" id="ids"/>
                                        <Input required name="employee_ids" id="employee_ids"
                                               placeholder="Employee Id" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Name <span className="text text-danger">*</span></label>
                                        <Input required name="name" id="name" required placeholder="name"
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Father Name <span className="text text-danger">*</span></label>
                                        <Input required name="father_name" id="father_name"
                                               placeholder="Father Name" title="Father Name"
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Gender <span className="text text-danger">*</span></label>
                                        <select required name="gender_id" id="gender_id" required
                                                className="form-control" title="Gender">
                                            <option value=""></option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Active <span className="text text-danger">*</span></label>
                                        <select required name="status_id" id="status_id" required
                                                className="form-control" title="Status Type Id">
                                            <option value="1">Active</option>
                                            <option value="2">Non-Active</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Email address <span
                                            className="text text-danger"></span></label>
                                        <Input name="email" id="email" placeholder="Email Address"
                                               type="email" title="Email Address"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Phone Number <span className="text text-danger">*</span></label>
                                        <Input type="tel"
                                               required
                                               name="phone" id="phone"
                                               placeholder="Phone Number"
                                               pattern="[1-9]{1}[0-9]{8}"/>
                                        <small className="badge badge-default"> Format: 700123456 </small>
                                        <br/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Salary <span className="text text-danger">*</span></label>
                                        <Input required name="salary" id='salary' placeholder="Salary" type="Number"
                                               title="Salary"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Position <span className="text text-danger">*</span></label>
                                        <select required name="position_id" id='position_id'
                                                className="form-control" title="Select position">
                                            <option value=""></option>
                                            {props.positionList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Attachment <span className="text text-danger">*</span></label>
                                        <input name="attachment" id='attachment' accept=".zip" type="file"
                                               title="Attach File"
                                               className="form-control"/>
                                        Select pdf file only.<br/>
                                        Maximum upload file size :5 MB
                                    </FormGroup>
                                    {props.attachFile == true &&
                                    <button className="btn btn-warning" onClick={() => downloadFile()}>
                                        {downloadStatus === true ? ("Loading . . . ") : ("Download File")}
                                    </button>
                                    }
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Remark <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" placeholder="Remark . . ." name="remark"
                                                  id="remark"></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row><Col><br/><br/>Access Management: <br/> <br/></Col> </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>User Type <span className="text text-danger">*</span></label>
                                        <select required name="user_type_id" id='user_type_id' className="form-control"
                                                onChange={e => onUserTypeChange(e)}
                                                title="Select User Type">
                                            <option value="1">App User</option>
                                            <option value="2">Sub Admin</option>
                                            <option value="3">Super Admin</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row id='permission_list'>
                                <Col className="pr-3" md="3">
                                    <FormGroup
                                        hidden={props.subAdminStatus == 1 || props.subAdminStatus == 2 ? false : true}>
                                        <label>Project <span className="text text-danger">*</span></label>
                                        <select
                                            required={props.subAdminStatus == 1 || props.subAdminStatus == 2 ? true : false}
                                            name="project_id" id='project_id'
                                            onChange={e => {
                                                props.setUpdateRecordStatus(false);
                                                onProjectChange(e.target.value)
                                            }}
                                            className="form-control" title="Select Project ">
                                            <option value=""></option>
                                            {
                                                props.projectList.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup
                                        hidden={props.subAdminStatus == 1 || props.subAdminStatus == 2 ? false : true}>
                                        <label>Province <span className="text text-danger">*</span></label>
                                        <select required={props.subAdminStatus == 1 ? true : false}
                                                name="province_id" id='province_id'
                                                onChange={e => {
                                                    props.setUpdateRecordStatus(false);
                                                    onProvinceChange(e.target.value);
                                                }}
                                                className=" form-control" title="Select Province">
                                            <option value="">{props.subAdminStatus == 2 ? ("*") : ""}</option>
                                            {
                                                provinceSubList.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup
                                        hidden={props.subAdminStatus == 1 || props.subAdminStatus == 2 ? false : true}>
                                        <label>District <span className="text text-danger">*</span></label>
                                        <select required={props.subAdminStatus == 1 ? true : false} name="branch_id"
                                                id='branch_id'
                                                onChange={e => {
                                                    props.setUpdateRecordStatus(false);
                                                    onBranchChange(e.target.value)
                                                }}
                                                className="form-control " title="Select Branch">
                                            <option value="">{props.subAdminStatus == 2 ? ("*") : ""}</option>
                                            {
                                                branchSubList.map((e, index) => (
                                                    <option key={index} value={e.id}>{e.branch_name}</option>
                                                ))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="3">
                                    <FormGroup hidden={props.subAdminStatus == 1 ? false : true}>
                                        <label>Class <span className="text text-danger">*</span></label>
                                        <select required={props.subAdminStatus == 1 ? true : false} name="class_id"
                                                id='class_id'
                                                className="form-control" title="Select Branch">
                                            <option value="">
                                                {classLoadStatus === false ? ("Loading . . . ") : ("")}
                                            </option>
                                            {classSubList.map((e, index) => (
                                                <option key={index} value={e.id}> {e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="3">
                                    <FormGroup hidden={props.subAdminStatus == 1 ? false : true}>
                                        <br/>
                                        <div style={{border: "1px solid gray", padding: "10px", borderRadius: "10px"}}>
                                            <Input style={{height: "25px", width: "25px", margin: "5px"}}
                                                   onClick={(e) => {
                                                       if (e.target.checked) {
                                                           setDisabled(false);
                                                       } else {
                                                           setDisabled(true);
                                                           document.getElementById('defaultPassword').value = "";
                                                       }
                                                   }}
                                                   type="checkbox" className=" checkbox" placeholder=""/>
                                            <label>Change Password <span className="text text-danger">*</span>
                                            </label>
                                            <Input name="defaultPassword" id="defaultPassword" disabled={disabled}
                                                   placeholder=""/>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setUpdateRecordStatus(false);
                                props.setShowModal(false)
                            }}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default AddProfile;
