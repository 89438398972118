import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, CardBody, Col, Input, Row} from "reactstrap";
import {Form, FormGroup} from "react-bootstrap";

function SearchItem(props) {

    const [projectList, setProjectList] = React.useState([]);
    const [provinceList, setProvinceList] = React.useState([]);
    const [branchList, setBranchList] = React.useState([]);
    const [positionList, setPositionList] = React.useState([]);
    // const [reportTypeId, setReportTypeId] = React.useState(reportType);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData= async()=>{
        await Promise.all([getPositions(),getBranchs(),getProvinces(),getProjects()])
            .then(function (results) {
                setPositionList(results[0].data);
                setBranchList(results[1].data);
                setProvinceList(results[2].data);
                setProjectList(results[3].data);
                props.setIsLoading(1);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }
    function getPositions() {
        return Axios.get(mainUrl + "getPosition",{headers});
    }
    function getProjects() {
        return Axios.get(mainUrl + "getProject",{headers});
    }
    function getBranchs() {
        return Axios.get(mainUrl + "getBranch",{headers});
    }
    function getProvinces() {
        return Axios.get(mainUrl + "getProvince",{headers});
    }

    var startDate = new Date();
    startDate.setDate(startDate.getDate()-30);
    var startDates= startDate.toISOString().substr(0, 10);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var endDates = endDate.toISOString().substr(0, 10);


    const setReport = async (e) => {
        e.preventDefault();
        props.setList([]);
        props.setReportType(e.target.value);
    }
    const generateReport = async (e) => {
        e.preventDefault();
        const f = e.target;

        if (props.reportType == 1) {

            props.setIsLoading(1);
            const hData= await Axios.post(mainUrl + "employeeById", {
                employee_id: f.employee_id.value
            }, {headers});

            const listsD = hData.data[0];
            if (listsD!= null) {
                props.setHeaderData(listsD);
                const {data} = await Axios.post(mainUrl + "generateReport", {
                    employee_id: f.employee_id.value,
                    start_date: f.start_date.value,
                    end_date: f.end_date.value,
                    type: 'analysis',
                }, {headers});
                const lists = data;
                props.setPeriodDate({
                    "startDate": document.getElementById('start_date').value,
                    "endDate": document.getElementById('end_date').value,
                    "workDays": lists.length
                })
                props.setList(lists);
            } else {
                props.setList([]);
                props.setHeaderData({});
            }
            props.setIsLoading(1);
        } else if (props.reportType == 2) {
            const {data} = await Axios.post(mainUrl + "generateReport", {
                employee_id: f.employee_id.value,
                project_id: f.project_id.value,
                province_id: f.province_id.value,
                branch_id: f.branch_id.value,
                position_id: f.position_id.value,
                start_date: f.start_date.value,
                end_date: f.end_date.value,
                totalHoliday: f.total_holiday.value,
                type: 'monthly'
            }, {headers});

            const lists = data;
            props.setList(lists);
        }
    }
    return (
        <>
            <CardBody className="mr-4 ml-4">
                <Form onSubmit={(e) => generateReport(e)}>
                    <Row>
                        <Col className="px-1" md="12">
                            <FormGroup>
                                <label>
                                    Report Type <span className="text text-danger">*</span></label>
                                <select name="report_type_id" id="report_type_id" title="Report type" defaultValue={props.reportType}
                                        className="form-control" onChange={(e) => setReport(e)}>
                                    <option value="0"></option>
                                    <option value="1">Review the Employee Salary Analysis</option>
                                    <option value="2">Monthly Salary Report</option>

                                </select>
                            </FormGroup>
                        </Col>
                        {(props.reportType == 1 || props.reportType == 2)&& (
                            <Col className="px-1" md="4">
                                <FormGroup>
                                    <label>Employee Id <span className="text text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Employee id"
                                           name="employee_id" id="employee_id"/>
                                </FormGroup>
                            </Col>)
                        }
                        {props.reportType == 2 && (
                            <>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Project <span className="text text-danger">*</span></label>
                                        <select name="project_id" id="project_id" title="Project"
                                                className="form-control">
                                            <option value=""></option>
                                            {projectList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Province <span className="text text-danger">*</span></label>
                                        <select name="province_id" id="province_id" title="Province"
                                                className="form-control">
                                            <option value=""></option>
                                            {provinceList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>District <span className="text text-danger">*</span></label>
                                        <select name="branch_id" id="branch_id" title="District"
                                                id="branch_id"
                                                className="form-control">
                                            <option value=""></option>
                                            {branchList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.branch_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Position <span className="text text-danger">*</span></label>
                                        <select name="position_id" id="position_id" title="Position"
                                                className="form-control">
                                            <option value=""></option>
                                            {positionList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Total Holiday <span className="text text-danger">*</span></label>
                                        <Input type="number" title="Total Holiday" placeholder="Total Holiday" id="total_holiday"
                                               defaultValue="4" name="total_holiday"/>
                                    </FormGroup>
                                </Col>
                            </>
                        )}
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Start Date <span className="text text-danger">*</span></label>
                                <Input type="date" title="Start Date" placeholder="Start Date" defaultValue={startDates}
                                       id="start_date"
                                       name="start_date"/>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>End Date <span className="text text-danger">*</span></label>
                                <Input type="date" title="End Date" placeholder="End Date" id="end_date"
                                       defaultValue={endDates}
                                       name="end_date"/>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <div className="ml-end">

                            <Button title="Generate Report" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Generate
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </Form>
            </CardBody>
        </>
    );
}



export default SearchItem;
