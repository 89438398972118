import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody, Input} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React from "react";
import axios from "axios";


function SearchProfile(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'province_id': f.sh_province_id.value,
            'email': f.sh_email.value,
            'branch_id': f.sh_branch_id.value,
            'project_id': f.sh_project_id.value,
            'position_id': f.sh_position_id.value,
            'employee_id': f.sh_employee_id.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchProfile?page=1",
            {
                'province_id': f.sh_province_id.value,
                'email': f.sh_email.value,
                'branch_id': f.sh_branch_id.value,
                'project_id': f.sh_project_id.value,
                'position_id': f.sh_position_id.value,
                'employee_id': f.sh_employee_id.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchProfile(e)}>
                    <Row>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Employee Id <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Employee Id" name="sh_employee_id" id="sh_employee_id"
                                       placeholder="Employee Id" type="text"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Email address <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Email Address" name="sh_email" id="sh_email"
                                       placeholder="Email Address" type="email"/>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Project <span className="text text-danger">*</span></label>
                                <select title="Select Project" name="sh_project_id" id='sh_project_id'
                                        className="form-control"
                                >
                                    <option value=""></option>
                                    {
                                        props.projectList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Province <span className="text text-danger">*</span></label>
                                <select title="Select Province" name="sh_province_id" id='sh_province_id'
                                        className="form-control"
                                >
                                    <option value=""></option>
                                    {
                                        props.provinceList.map((e, index) => (
                                            <option key={index}
                                                    value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>

                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>District <span className="text text-danger">*</span></label>
                                <select title="Select District" name="sh_branch_id" id="sh_branch_id"
                                        className="form-control">
                                    <option value=""></option>
                                    {props.branchList.map((e, index) => (
                                        <option key={index}
                                                value={e.id}>{e.branch_name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Position <span className="text text-danger">*</span></label>
                                <select  title="Select Position" name="sh_position_id" id="sh_position_id"
                                        className="form-control">
                                    <option value=""></option>
                                    {props.positionList.map((e) => (
                                        <option key={e.id}
                                                value={e.id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchProfile;
