import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Modal, Row, Spinner, Table, Dropdown} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import toast, {Toaster} from "react-hot-toast";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListProfile(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showBlockModal, setShowBlockModal] = React.useState(false);
    const [accountStatus, setAccountStatus] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteProfile", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchProfile?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getProfile?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const onBlockRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "blockAccount", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Account Status has been changed!");
            for (const i in props.list.data) {
                if (props.list.data[i].id == recordId) {
                    if(props.list.data[i].is_active==1 ){
                        props.list.data[i].is_active = 0;
                    }else{
                        props.list.data[i].is_active=1;
                    }
                    break;
                }
            }
            setShowBlockModal(false);
        }).catch((error) => {
            props.notifyError(error.response.data.message);

            setShowBlockModal(false);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const onForgotPassword = async (e) => {
        await Axios.post(mainUrl + "forgotPassword", {
            email: e
        }, {headers});

        setRecordId(0);
        setShowDeleteModal(false);
    }


    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function BlockItem() {
        return (
            <>
                <Modal show={showBlockModal} backdrop="static" size="lg" onHide={() => setShowBlockModal(false)}>
                    <form onSubmit={(e) => onBlockRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to <span
                                    className="badge badge-primary">{accountStatus}</span> this Account?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowBlockModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            <BlockItem/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> User Profile </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setAttachFiles(false);
                                                    props.setShowModal(true);
                                                    props.setSubAdminStatus(1);
                                                    props.setLocalImageProfile("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>image</th>
                                        <th>Employee Id</th>
                                        <th>Employee Name</th>
                                        <th>Phone</th>
                                        <th>Account Status</th>
                                        <th>Position</th>
                                        <th>Project</th>
                                        <th>Province</th>
                                        <th>District</th>
                                        <th className="text-right">Employee Status</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            {/*className={rowNo % 2 == 0 ? "bg-dark bg-opacity-10" : ""}*/}
                                            <td>{rowNo++}</td>
                                            <td>
                                                <img width="64" height="auto" src={e.image} style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "#8080805e!important"
                                                }}/>

                                            </td>
                                            <td style={{width: "10%"}}> ( {e.employee_id} ) </td>
                                            <td style={{width: "10%"}}> {e.name}</td>
                                            <td style={{width: "10%"}}>{e.phone}</td>
                                            <td style={{width: "15%", whiteSpace: "nowrap"}}>
                                                {(e.login_status === 1 && e.user_type_id == '1') &&
                                                <span className="badge badge-success ">AppUser</span>
                                                }
                                                {(e.login_status === 0 && e.user_type_id == '1') &&
                                                <span className="badge badge-danger ">AppUser</span>
                                                }
                                                {(e.login_status === 0 && e.user_type_id == '2') &&
                                                <span className="badge badge-success ">SUB ADMIN</span>
                                                }
                                                {(e.login_status === 1 && e.user_type_id == '2') &&
                                                <span className="badge badge-danger ">SUB ADMIN</span>
                                                }
                                                {(e.login_status === 1 && e.user_type_id == '3') &&
                                                <span className="badge badge-success ">SUPER ADMIN</span>
                                                }
                                                {(e.login_status === 0 && e.user_type_id == '3') &&
                                                <span className="badge badge-danger ">SUPER ADMIN</span>
                                                }

                                                &nbsp;&nbsp;
                                                {e.is_active === 0 &&
                                                <span className="badge badge-default ">
                                                     &nbsp;&nbsp; <span className="nc-icon nc-key-25"/>&nbsp;&nbsp;
                                                </span>
                                                }

                                            </td>
                                            <td style={{width: "15%"}}>{e.position_name}</td>
                                            <td style={{width: "15%"}}>{e.project_name}</td>
                                            <td style={{width: "15%"}}>{e.province_name}</td>
                                            <td style={{width: "10%"}}>{e.branch_name} </td>
                                            <td style={{width: "10%", whiteSpace: "nowrap"}}>
                                                {e.status_id === 1 &&
                                                <span className="badge badge-primary ">Active</span>
                                                }
                                                {e.status_id === 2 &&
                                                <span className="badge badge-warning ">Non Active</span>
                                                }
                                            </td>
                                            <td>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>


                                                        <Button title="Update & Detail View Record"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round" variant="light" type="Button"
                                                                onClick={async () => {

                                                                    await props.setShowModal(true);
                                                                    setRecordId(e.id);
                                                                    document.getElementById('ids').value = e.id;
                                                                    document.getElementById('employee_ids').value = e.employee_id;
                                                                    document.getElementById('name').value = e.name;
                                                                    document.getElementById('phone').value = e.phone;
                                                                    document.getElementById('father_name').value = e.father_name;
                                                                    document.getElementById('email').value = e.email;
                                                                    document.getElementById('phone').value = e.phone;
                                                                    document.getElementById('gender_id').value = e.gender_id;
                                                                    document.getElementById('user_type_id').value = e.user_type_id;
                                                                    document.getElementById('salary').value = e.salary;
                                                                    // document.getElementById('attachment').value = e.attachment;
                                                                    props.setAttachFiles(false);
                                                                    props.setSubAdminStatus(e.user_type_id);
                                                                    if (e.attachment != null && e.attachment != "") {
                                                                        props.setAttachFiles(true);
                                                                    }
                                                                    document.getElementById('status_id').value = e.status_id;
                                                                    document.getElementById('image').value = e.image;
                                                                    sessionStorage.setItem("image", e.image);
                                                                    props.setLocalImageProfile(e.image);
                                                                    document.getElementById('project_id').value = e.project_id;
                                                                    document.getElementById('province_id').value = e.province_id;
                                                                    document.getElementById('position_id').value = e.position_id;
                                                                    document.getElementById('branch_id').value = e.branch_id;

                                                                    props.setNeedObject({
                                                                        'project_id': e.project_id,
                                                                        'province_id': e.province_id,
                                                                        'branch_id': e.branch_id,
                                                                        'class_id': e.class_id
                                                                    });
                                                                    props.setUpdateRecordStatus(true);


                                                                }}>

                                                            <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Update & Detail View Record
                                                        </Button>
                                                        <Button title="Update & Detail View Record"
                                                                className="btn-round dropdownlist " variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                        <Button title="   Block & Unblock Account"
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    if (e.is_active === 0) {
                                                                        setAccountStatus('Unblock');
                                                                    } else {
                                                                        setAccountStatus('Block');
                                                                    }
                                                                    setShowBlockModal(true);
                                                                }}>

                                                            <span className="nc-icon nc-key-25 text-info"/> &nbsp;&nbsp;
                                                            Block & unBlock Account
                                                        </Button>


                                                        <Button title="Forgot Password"
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    toast.promise(
                                                                        onForgotPassword(e.email),
                                                                        {
                                                                            loading: 'Sending...',
                                                                            success: <b>We have e-mailed for this
                                                                                account password link!!</b>,
                                                                            error: <b>Server Error!</b>,
                                                                        }
                                                                    );
                                                                }}>
                                                            <span className="nc-icon nc-send text-info"/> &nbsp;&nbsp;
                                                            Forgot Password
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                onClick={(pageNumber => {
                                                    nextPageLoadList(pageNumber);
                                                })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                        :"")}
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListProfile;
