
const englishWords= {
    "totalAdminUser": "Total User",
    "ClassManagement&MonitoringSystem": "Class Management & Monitoring System",
    "dashboard": "Dashboard",
    "userProfile": "User Profile",
    "setting": "Setting",
    "report": "Report"
}


export default englishWords;